/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import CustomModalHeader from 'components/modal/customModalHeader';
import useUser from 'hooks/useUser';
import { Modal } from 'flowbite-react';
import  { useEffect, useRef, useState } from 'react';
import { themModal } from 'utils/theme';
import '../../form/styles.scss';
import GroupButton from 'components/button/groupButton';
import { exportStockFormOrg } from 'api/stockApi';
import { useSelector } from 'react-redux';
import { locationSelector, orgSelector, userSelector } from 'redux/selectors';
import MultiSelect from 'components/select/index';
import { getCategoriesByFilter } from 'api/categoryApi';
import { pageType, permissionKeys, sortByTypeType } from 'utils/constants';
import { toast } from 'react-toastify';
import { checkOrgAdmin, checkPermission, getUserOrg } from 'utils/utils';
import { getLocationByFilter } from 'api/locationApi';

type exportStockModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  headerTitle?: string
};
const ExportStockModal = (props: exportStockModalProps) => {
  const { openModal, setOpenModal, headerTitle } = props;
  const { fetchCurrentUser } = useUser();
  const [t] = useTranslation();
  const { userInfo } = useSelector(userSelector);
  const rootRef = useRef(null);
  const { organizationId } = useSelector(orgSelector);
  const { locationId } = useSelector(locationSelector);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryTypeOptions, setCategoryTypeOptions] = useState([{ label: '...', value: '' }]);
  const [selectedItemCount, setSelectedItemCount] = useState([]);
  const [categoryIds, setCategoryIds] = useState('');
  const [itemCountOptions, setItemCountOptions] = useState([{ label: 'All', value: 'all' }, { label: 'Negative', value: 'negative' }, { label: 'Positive', value: 'positive' }]);
  const [itemCount, setItemCount] = useState('all');
  const [selectedType, setSelectedType] = useState<any>({label: 'This Location', value: "This"});
  const [typeOptions, setTypeOptions] = useState([
            {label: 'This Location', value: "This"},
            {label: 'All My Locations', value: "All"}
        ]);
  const {WRITE_LOCATION, PLATFORM_ADMIN} = permissionKeys;
  const isAdmin = checkPermission(userInfo, pageType.LOCATION, [PLATFORM_ADMIN], organizationId);
  const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);
      
    
  const getRegions = () => {
        const userOrg = userInfo?.organizations?.find((o: any) => o.id === organizationId);
        const orgRegions = userOrg?.regions;
        let regionIds = '';
        if (orgRegions) {
            for (let index = 0; index < orgRegions.length; index++) {
                const region = orgRegions[index];
                if (regionIds === '') {
                    regionIds = region.id;
                } else {
                    regionIds = `${regionIds},${region.id}`;
                }
            }
        }
        return regionIds;
    }

  useEffect(() => {
    getCategoriesByFilter({ page: 0, limit: 0, sortByType: sortByTypeType.DESC, organizationId })?.then(res => {
      const newOption = res?.data?.entities?.map((i: any) => ({ label: i.name, value: i.id }));
      setCategoryTypeOptions(newOption);
    });
      // eslint-disable-next-line
  }, []);

  const toggleHandler = () => {
    setOpenModal(!openModal);
  };

  const handleExportItem = async () => {
    setSelectedType(selectedType);
    if (selectedType?.value === "This") {
      const res = await exportStockFormOrg(organizationId, {categoryIds, itemCount, locationId});
      if (res.data) {
          if (res.status === 200) {
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `stock-export.xlsx`);
              document.body.appendChild(link)
              link.click();

              // Remove dom after finish
              document.body.removeChild(link);
          } else {
              if (res.status === 204) {
                  toast.error(`${t('uploadFile.noItemExport')}`);
              } else {
                  toast.error(`${t('uploadFile.exportError')}`);
              }
          }
        }
    } else {
      let ids = '';
            const resLocation = await getLocationByFilter({ page: 0, limit: 0, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId, regionIds: isOrgAdmin === true ? '' : getRegions(), isActive: true });
            if (resLocation.data) {
                for (let index = 0; index < resLocation.data?.entities?.length; index++) {
                    const element = resLocation.data?.entities[index];
                    if (ids === '') {
                        ids = element.id;
                    } else {
                        ids = `${ids},${element.id}`;
                    }
                }
            }
      const res = await exportStockFormOrg(organizationId, {categoryIds, itemCount, locationIds: ids});
      if (res.data) {
          if (res.status === 200) {
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `stock-export.xlsx`);
              document.body.appendChild(link)
              link.click();

              // Remove dom after finish
              document.body.removeChild(link);
          } else {
              if (res.status === 204) {
                  toast.error(`${t('uploadFile.noItemExport')}`);
              } else {
                  toast.error(`${t('uploadFile.exportError')}`);
              }
          }
        }
    }
  };

  const handleChangeCategory = (option: any) => {
    setSelectedCategory(option);

    let cateIds = '';
    for (let index = 0; index < option.length; index++) {
        const element = option[index];
        if (cateIds === '') {
            cateIds = element.value;
        } else {
            cateIds = `${cateIds},${element.value}`;
        }
    }
    setCategoryIds(cateIds);
  };

  const handleChangeItemCount = (option: any) => {
    setSelectedItemCount(option);
    setItemCount(option.value);
  };

  const handleChangeType = (option: any) => {
    setSelectedType(option);
};

  return (
    <div ref={rootRef} className="group-modal-container">
      <Modal
        show={openModal}
        size="lg"
        className="group-modal"
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        //dismissible={true}
        theme={themModal}
      >
        <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
        <Modal.Body theme={{ base: 'flex-12 pb-4 pt-3 px-12' }}>
            <div className="mt-2 relative flex md:flex-row flex-col">
                <MultiSelect
                    onChange={handleChangeCategory}
                    options={categoryTypeOptions}
                    value={selectedCategory}
                    name="categoryId"
                    noOptionsMessage={() => t('No options')}
                    closeMenuOnSelect
                    isHolderLabel={true}
                    placeholder={`${t('sidebar.category')}`}
                    isMulti={true}
                />
            </div>
            <div className="mt-6 relative flex md:flex-row flex-col">
                <MultiSelect
                        onChange={handleChangeItemCount}
                        options={itemCountOptions}
                        value={selectedItemCount}
                        name="itemCount"
                        noOptionsMessage={() => t('No options')}
                        closeMenuOnSelect
                        isHolderLabel={true}
                        placeholder={`${t('stockPage.itemCount')}`}
                    />
            </div>
            <div className="mt-6 relative flex md:flex-row flex-col">
                <MultiSelect
                        onChange={handleChangeType}
                        options={typeOptions}
                        value={selectedType}
                        name="type"
                        noOptionsMessage={() => t('No options')}
                        closeMenuOnSelect
                        isHolderLabel={true}
                        placeholder={`${t('orderPage.location')}`}
                    />
            </div>
            <GroupButton
              className="items-center justify-center pt-5 mb-4"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.cancel'),
                  classType: 'white',
                  action: () => toggleHandler(),
                },
                {
                  type: 'button',
                  text: t('modal.export'),
                  classType: 'blue',
                  action: () => handleExportItem(),
                },
              ]}
            />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ExportStockModal;
