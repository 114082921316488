// eslint-disable-next-line
import { myTokenType, orgTypes, userType, locationTypes, categoryTypes, itemTypes, stockTypes, regionTypes, orderTypes, notificationTypes } from './proptypes';

const initUser: userType = {
  id: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  roles: [],
  isActive: true,
  created: new Date(),
};

const initOrganization: orgTypes = {
  id: '',
  name: '',
  userOrganizations: [],
  isActive: true,
};

const initLocation: locationTypes = {
  id: '',
  name: '',
  clubCode: '',
  region: null,
  userOrgs: [],
  isActive: true,
};

const initRegion: regionTypes = {
  id: '',
  name: '',
  locations: [],
  parentGroup: null,
  userOrgs: [],
  isActive: true,
};

const initCategory: categoryTypes = {
  id: '',
  name: '',
  organizationId: '',
  countItem: 0,
  isActive: true,
};

const initItem: itemTypes = {
  id: '',
  name: '',
  organizationId: '',
  isActive: true,
  sku: '',
  upc: '',
  bulkUpc: '',
  unitCost: 0,
  unitPrice: 0
};

const initStock: stockTypes = {
  id: '',
  name: '',
  locationId: '',
  location: null,
  itemId: '',
  item: null,
  quantityOnHand: 0
};

const initOrder: orderTypes = {
  id: '',
  name: '',
  locationId: '',
  location: null,
  status:'Draft',
  orderedOn: new Date(),
  purchaseOrderItems: null
};

const initNotification : notificationTypes = {
  id: '',
  message: '',
  created: ''
}

const initToken: myTokenType = {
  token: '',
  expiredDate: '',
  status: 'Active',
};
export { initUser, initOrganization, initToken, initLocation, initRegion, initCategory, initItem, initStock, initOrder, initNotification };
