const Hightlight = (props: any) => {
    const { count, className = '' } = props;
    return (
      <p
        className={`bg-red-600 rounded rounded-[100px] px-1 text-white text-[10px] font-bold h-[18px] min-w-[18px] flex items-center justify-center ${className}`}
      >
        {count < 5 ? count : 5}
        {count > 5 ? '+' : ''}
      </p>
    );
  };
  
  export default Hightlight;
  