import {notificationTypes} from 'utils/proptypes';
import PaginateTable from 'components/table/paginate';
import {HiOutlineDuplicate, HiOutlineEye, HiOutlineEyeOff} from 'react-icons/hi';
import {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {initNotification} from 'utils/initData';
import SpinnerComponent from 'components/spinner';
import {defaultpageCount, permissionKeys, sortByTypeType} from 'utils/constants';
import {checkOrgAdmin, checkPermission, copyToClipboard, formatDateWithTime} from '../../utils/utils';
import {useSelector} from 'react-redux';
import {locationSelector, orgSelector, userSelector} from 'redux/selectors';
import useUser from 'hooks/useUser';
import '../../styles/styles.scss';
import {Table, Tooltip} from 'flowbite-react';
import Sort from 'components/table/sort';
import EmptyState from 'components/commonComponent/emptyState';
import ActionTableWithFilter from 'components/table/actionTableWithFilter';
import { getNotificationByFilter, updateNotificationById, getNotificationById, updateAllNotifications } from 'api/notificationApi';
import { toast } from 'react-toastify';
import GroupButtonWithIcons from 'components/button/buttonGroupWithIcons';

const OrderEmpty = require('../../assets/image/svg/shopping-cart.svg').default || '';

const NotificationList = (props: any) => {
    const queryClient = useQueryClient();
    const {WRITE_ORG, PLATFORM_ADMIN} = permissionKeys;
    const [t] = useTranslation();
    const {handleSetTookie} = useUser();
    const {userInfo} = useSelector(userSelector);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [notifications, setNotifications] = useState([initNotification]);
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [sortBy, setSortBy] = useState('');
    const [sortByType, setSortByType] = useState('');
    const navigate = useNavigate();
    const [pageCount, setPageCount] = useState(defaultpageCount);
    const {organizationId} = useSelector(orgSelector);
    const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_ORG], organizationId);
    const {locationId} = useSelector(locationSelector);
    const [location, setLocation] = useState('');
    const [region, setRegion] = useState('');
    const [fDate, setFDate] = useState('');
    const [tDate, setTDate] = useState('');
    const [isUnread, setIsUnread] = useState(undefined);
    const isAdmin = checkPermission(userInfo, props.type, [PLATFORM_ADMIN], organizationId);
    const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);

    const onRowClickHandler = async (id: string, type: string) => {
        const res = await getNotificationById(id);
        switch (type) {
            case "unread":
                if (res?.data) {
                    const payload = res.data;
                    payload.readOn = null;
                    payload.readBy = null;
                    const response = await updateNotificationById(id, payload);
                    if (response.status === 204) {
                        await queryClient.invalidateQueries(['getNotifications'], {refetchType: 'active'}, {cancelRefetch: true});
                        const message: string = t('notificationPage.editSuccess');
                        toast.success(message);
                    }
                }
                break;
            case "read":
                    if (res?.data) {
                        const payload = res.data;
                        payload.readOn = new Date();
                        payload.readBy = userInfo.id;
                        payload.userReadBy = { id: userInfo.id };
                        const response = await updateNotificationById(id, payload);
                        if (response.status === 204) {
                            await queryClient.invalidateQueries(['getNotifications'], {refetchType: 'active'}, {cancelRefetch: true});
                            const message: string = t('notificationPage.editSuccess');
                            toast.success(message);
                        }
                    }
                    break;
        
            default:
                break;
        }
    };

    const handleUpdateNotifications = async () => {
        const resList = await getNotificationByFilter({page: 0, limit: 0, organizationId, locationId: location, regionId: region, userId: isAdmin ? null : userInfo.id, fDate, tDate, isUnread: true});
        const ids = resList?.data?.entities?.map((x: any) => x.id);
        const res = await updateAllNotifications(userInfo.id, {ids});
        if (res.status === 204) {
            await queryClient.invalidateQueries(['getNotifications'], {refetchType: 'active'}, {cancelRefetch: true});
            const message: string = t('notificationPage.editSuccess');
            toast.success(message);
        }
    }

    const {data, isLoading, refetch, error} = useQuery(
        ['getNotifications', currentPage, searchValue, sortBy, sortByType, pageCount],
        () => getNotificationByFilter({
            page: currentPage,
            limit: pageCount,
            searchQuery: searchValue,
            sortBy,
            sortByType,
            organizationId,
            locationId: location,
            regionId: region,
            userId: isAdmin ? null : userInfo.id,
            fDate,
            tDate,
            isUnread
        }),
        {
            staleTime: Infinity,
        },
    );

    useEffect(() => {
        if (error) {
            setNotifications([initNotification]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const handleSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(0);
    };

    const handleCopyToClipboard = (e: any, item: any, type: string) => {
        switch (type) {
            case 'message':
                copyToClipboard(item.id, t('notificationPage.copiedMessage'));
                break;
        }
        e.stopPropagation();
    };

    const handleHeaderSort = (field: string) => {
        setSortBy(field);
        let type = '';
        if (field !== sortBy) {
            type = sortByTypeType.ASC;
        } else if (sortByType === sortByTypeType.ASC) {
            type = sortByTypeType.DESC;
        } else {
            setSortBy(type);
        }
        setSortByType(type);
    };

    useEffect(() => {
        refetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, location, region, fDate, tDate, isUnread]);

    useEffect(() => {
        if (data) {
            setNotifications(data?.data?.entities || []);
            setTotalEntities(data.data?.totalEntities || 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setTotalEntities(data?.data.totalEntities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    return (
        <>
            {error ? <EmptyState buttonName={''}
                                 handleAddClick={undefined}
                                 title={t('notificationPage.titleNoResult')}
                                 subtitle={''}
                                 icon={OrderEmpty}
                /> :
                <div className="main-sub">
                        <ActionTableWithFilter
                            placeholderSearch={'Search'}
                            buttonName={t('notificationPage.markAllRead')}
                            handleAddClick={handleUpdateNotifications}
                            handleSearch={handleSearch}
                            location={location}
                            setLocation={setLocation}
                            region={region}
                            setRegion={setRegion}
                            fdate={fDate}
                            setFDate={setFDate}
                            tDate={tDate}
                            setTDate={setTDate}
                            isUnread={isUnread}
                            setIsUnread={setIsUnread}
                            type="notificationList"
                        />
                        <Table className="border-b">
                            <Table.Head className="text-xs text-gray-900 border-b-2 border-gray-100">
                                <Table.HeadCell className="cursor-pointer w-110 bg-white font-medium text-xs px-3"
                                                onClick={() => handleHeaderSort('message')}>
                                    <div className="flex items-center ">
                                        {t('notificationPage.message').toUpperCase()}
                                        <Sort check={sortBy === 'message'} sortByType={sortByType}/>
                                    </div>
                                </Table.HeadCell>
                                <Table.HeadCell className="cursor-pointer w-70 bg-white font-medium text-xs"
                                                onClick={() => handleHeaderSort('created')}>
                                    <div className="flex items-center ">
                                        {t('notificationPage.time').toUpperCase()}
                                        <Sort check={sortBy === 'created'} sortByType={sortByType}/>
                                    </div>
                                </Table.HeadCell>
                                <Table.HeadCell className="w-8 bg-white">{''}</Table.HeadCell>
                            </Table.Head>
                            <Table.Body>
                                {notifications.map((item: notificationTypes, index) => (
                                    <Table.Row key={`row-${index + 1}`}
                                               className="bg-white text-sm hover:bg-gray-50 border-b border-gray-100">
                                        <Table.Cell className="font-semibold py-[10px] px-3">
                                            <div className="flex flex-row items-center"
                                                 onClick={() => {}}>
                                                { item.readOn ? 
                                                (
                                                    <>
                                                        <aside className="flex flex-row items-center">
                                                                <span
                                                                    className={`w-3 h-3 flex bg-white rounded-lg mr-2`}/>
                                                            <div className="text-black">{item?.message}</div>
                                                        </aside>
                                                    </>
                                                ) : 
                                                (
                                                    <>
                                                        <aside className="flex flex-row items-center">
                                                                <span
                                                                    className={`w-3 h-3 flex bg-blue-500 rounded-lg mr-2`}/>
                                                            <div className="text-black font-extrabold">{item?.message}</div>
                                                        </aside>
                                                    </>
                                                )} 
                                                <div
                                                    className="copy-icon pl-3 cursor-pointer text-gray-800">
                                                    <HiOutlineDuplicate size={25}
                                                                        onClick={(e: any) => handleCopyToClipboard(e, item, 'message')}/>
                                                </div>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className="py-[10px]">
                                            <div className="flex flex-row items-center"
                                                 onClick={() => {}}>
                                                <div className="text-black">{formatDateWithTime(item?.created)}</div>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className="flex items-center justify-end">
                                            {isWriteOrg && (
                                                <>
                                                    <GroupButtonWithIcons
                                                                className="items-center justify-center"
                                                                buttons={[
                                                                    {
                                                                        type: 'button',
                                                                        text: '',
                                                                        classType: 'white',
                                                                        icon: !item?.readOn ? <HiOutlineEyeOff size={"20px"}/> : <Tooltip content={`Read by ${item?.userReadBy?.firstName} ${item?.userReadBy?.lastName}`}><HiOutlineEye size={"20px"}/></Tooltip>,
                                                                        action: item?.readOn ? () => onRowClickHandler(item?.id, "unread") : () => onRowClickHandler(item?.id, "read"),
                                                                    },
                                                                ]}
                                                            />
                                                </>
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                        {isLoading && <SpinnerComponent/>}
                        <PaginateTable
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            totalEntities={totalEntities}
                            isLoadingTable={isLoading}
                        />
                </div>}
        </>
    );
};

export default NotificationList;
