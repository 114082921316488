// eslint-disable-next-line
import { notificationQueryFilter } from 'utils/proptypes';
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

const { urls, methods, execute } = base();
const getNotificationByFilter = (queryFilter: notificationQueryFilter) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.notification.notification}/?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getNotifications = () => {
  const method = methods.GET;
  const url = `${urls.notification.notification}?limit=0`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getNotificationById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.notification.notification}/${id}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const createNotification = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.notification.notification}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updateNotificationById = (id: any,payload: any) => {
  const method = methods.PUT;
  const url = `${urls.notification.notification}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const updateAllNotifications = (userId: any, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.notification.notification}/update-all/${userId}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const deleteNotificationById = (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.notification.notification}/${id}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

export {
    getNotificationByFilter,
    getNotifications,
    getNotificationById,
    createNotification,
    updateAllNotifications, updateNotificationById,
    deleteNotificationById
};
